<template>
  <div id="app">
    <b-navbar type="dark" variant="dark">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand :to="{ path: '/' }">
        KNCO - Speech recognition
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse" >
        <b-navbar-nav v-if="isAuthenticated" class="ml-auto">
          <b-nav-item class="navbar_items" to="/"> Speech recognition </b-nav-item>
          <b-nav-item class="navbar_items" to="/logout">Se déconnecter</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-else class="ml-auto">
          <b-nav-item class="navbar_items" to="/login">Se connecter</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div id="app-body">
      <globalErrorModalComponent />
      <LoadingComponent />
      <router-view/>
    </div>
  </div>
</template>

<script>
import globalErrorModalComponent from '@/components/globalErrorModal'
import LoadingComponent from '@/components/loading'

export default {
  name: 'App',
  components : {
    globalErrorModalComponent,
    LoadingComponent
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.getters.TOKEN
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
