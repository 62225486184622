import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = {
  isGlobalErrorModal: false,
  isAPILoading: false,
}

const getters = {
  isAPILoading: state => !!state.isAPILoading,
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  openGlobalErrorModal (state) {
    state.isGlobalErrorModal = true
  },
  closeGlobalErrorModal (state) {
    state.isGlobalErrorModal = false
  },
  loadFromAPI (state) {
    state.isAPILoading = true
  },
  endLoadingFromAPI (state) {
    state.isAPILoading = false
  },
}


export default new Vuex.Store({
  modules: {
    user,
    auth,
  },
  strict: debug,
  state,
  getters,
  mutations
})
