// https://github.com/bryanjenningz/record-audio/blob/master/record-server-example/public/index.html
const recordAudio = () =>
    // eslint-disable-next-line
    new Promise(async resolve => {
    const constraints = {
        audio: {
            sampleRate: 48000,
            channelCount: 1,
        },
        video: false
    }
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const options = {
        mimeType: 'audio/webm'
    }
    const mediaRecorder = new MediaRecorder(stream, options);
    let audioChunks = [];

    mediaRecorder.addEventListener('dataavailable', event => {
        audioChunks.push(event.data);
    });

    const start = () => {
        audioChunks = [];
        mediaRecorder.start();
    };

    const stop = () =>
        new Promise(resolve => {
        mediaRecorder.addEventListener('stop', () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            const play = () => audio.play();
            resolve({ audioChunks, audioBlob, audioUrl, play });
        });

        mediaRecorder.stop();
        });

    resolve({ start, stop });
    });

export default recordAudio