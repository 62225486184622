<template>
  <div class="home">
    <div class="container">

      <div class="mt-4">
        <h3>Reconnaissance vocale - lunettes</h3>
        <p>
          Deux méthodes sont utilisées pour vous permettre d'utiliser la reconnaissance vocale
          pour reconnaître les références des lunettes :
          <ul>
            <li><strong>La méthode "Marques + références"</strong>. Elle consiste à d'abord reconnaître la marque puis ensuite
            reconnaître la référence. Par exemple, pour reconnaître <i>AXARIANE01-Bw-M</i>, vous reconnaissez d'abord
            la marque en prononçant "AX" puis, une fois la marque identifiée, vous prononcez "Ariane 0 1 B W" pour
            reconnaître la référence.
            </li>
            <li><strong>La méthode "Tout en un"</strong>.Elle consiste à dire l'intégralité de la référence
            de la lunette. Par exemple, pour reconnaître <i>AXARIANE01-Bw-M</i>, vous pouvez prononcer
            "A X Ariane 0 1 B W" ou "A X A R I A N E 0 1 B W".
            </li>
          </ul>
        </p>
      </div>
      <b-tabs pills content-class="mt-3">

        <b-tab active title="Tout en un">
         <div class="mt-4">
            <h4>Identification de la paire de lunette </h4>
          </div>
          <div>
            <p>
              Cliquez sur "Enregistrer", prononcer la référence de la paire de lunetttes cliquez sur "Stop"<br>
              <i> Exemple : "K A 5377 C 68"  pour KA5377-C68-M </i>
            </p>

          </div>
          <recorder @stop="speechRecognizeLunettes" />

          <div class="mt-4" v-if="hasSearchedLunettes">
              <p>
                <strong>Référence identifiée : {{chosenLunettes?.reference}} </strong>
              </p>
              <p>
                Liste des références potentielles <i>(cliquez pour choisir)</i>:
                  <b-button
                    class="mr-2"
                    size="sm"
                    variant="outline-secondary"
                    v-for="lunette in lunettes"
                    :key="lunette.text"
                    @click="chosenLunettes = lunette"
                  >
                    {{lunette.reference}}
                  </b-button>
              </p>
              <p>
                <i>Le speech recognition a reconnu : "{{lunettesSpeechRecognize.transcript}}"
                  avec une confiance de {{parseFloat(lunettesSpeechRecognize.confidence).toFixed(2)}}
                </i>
              </p>
            </div>

            <div v-if="chosenLunettes">
              <hr>
              <p>
                <strong> La paire de lunette identifiée est : {{chosenLunettes.reference}} </strong>
              </p>
              <b-button
                variant="outline-success"
                @click="validateReference('lunettes')"
              >
                Valider
              </b-button>
            </div>

        </b-tab>
        <b-tab title="Marques + références">
          <div class="mt-4">
            <h4> 1. Identification de la marque </h4>
          </div>
          <div>
            <p>
              <strong>Cliquez sur "Enregistrer"</Strong>, prononcer les acronymes de la marque puis cliquez sur "Stop"<br>
              <i> Exemple : AX pour AXEBO </i>
              ou <strong> choisissez la marque </strong> dans la liste suivante
            </p>
          </div>

          <recorder @stop="speechRecognizeMarques" />
          <b-form-select
            class="mt-2"
            v-model="chosenMarque.acronyme"
            :options="marques"
            value-field="acronyme"
            text-field="acronyme"
          />


          <div class="mt-4" v-if="hasSearchedMarques">
            <p>
              <strong>Marque identifié : {{chosenMarque?.acronyme}} - {{chosenMarque?.marque}}</strong>
            </p>
            <p>
              Liste des marques potentielles <i>(cliquez pour choisir)</i>:
                <b-button
                  class="mr-2"
                  size="sm"
                  variant="outline-secondary"
                  v-for="marque in marquesProposed"
                  :key="marque.text"
                  @click="chosenMarque = marque"
                >
                  {{marque.marque}}
                </b-button>
            </p>
            <p>
              <i>Le speech recognition a reconnu : "{{marqueSpeechRecognize.transcript}}"
                avec une confiance de {{parseFloat(marqueSpeechRecognize.confidence).toFixed(2)}}
              </i>
            </p>
          </div>


          <div v-if="chosenMarque">
            <div class="mt-4">
              <h4> 2. Identification de la référence </h4>
            </div>
            <div>
              <p>
                Cliquez sur "Enregistrer", prononcer la référence de la lunette puis cliquez sur "Stop"<br>
                <i> Exemple : 100001 pour AX1000-01-M </i>
              </p>
            </div>
            <recorder @stop="speechRecognizeReferences" />

            <div class="mt-4" v-if="hasSearchedReferences">
              <p>
                <strong>Référence identifiée : {{chosenReference?.reference}} </strong>
              </p>
              <p>
                Liste des références potentielles <i>(cliquez pour choisir)</i>:
                  <b-button
                    class="mr-2"
                    size="sm"
                    variant="outline-secondary"
                    v-for="reference in references"
                    :key="reference.text"
                    @click="chosenReference = reference"
                  >
                    {{reference.reference}}
                  </b-button>
              </p>
              <p>
                <i>Le speech recognition a reconnu : "{{referencesSpeechRecognize.transcript}}"
                  avec une confiance de {{parseFloat(referencesSpeechRecognize.confidence).toFixed(2)}}
                </i>
              </p>
            </div>

            <div v-if="chosenMarque && chosenReference">
              <hr>
              <p>
                <strong> La paire de lunette identifiée est : {{chosenReference.reference}} </strong>
              </p>
              <b-button
                variant="outline-success"
                @click="validateReference('reference')"
              >
                Valider
              </b-button>
            </div>
          </div>
        </b-tab>
      </b-tabs>



    </div>
  </div>
</template>

<script>
import { APIService } from '@/api/Api'
import recorder from '@/components/recorder'

const api = new APIService()

export default {
  name: 'HomeView',
  components: {
    recorder
  },
  data() {
    return {
      chosenLunettes: null,
      chosenMarque: {
        acronyme: null
      },
      chosenReference: null,
      hasSearchedLunettes: false,
      hasSearchedMarques: false,
      hasSearchedReferences: false,
      lunettes: [],
      lunettesSpeechRecognize: {
        transcript: '',
        confidence: ''
      },
      marques: [],
      marquesProposed: [],
      marqueSpeechRecognize: {
        transcript: '',
        confidence: ''
      },
      references: [],
      referencesSpeechRecognize: {
        transcript: '',
        confidence: ''
      },
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    selectedMarques: {}
  },
  mounted () {
    this.getMarques()
  },
  methods: {
    getMarques () {
      this.$store.commit('loadFromAPI')
      api.getMarques(this.token)
      .then((result) => {
        this.marques = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error(String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    speechRecognizeLunettes (audio) {
      this.$store.commit('loadFromAPI')
      api.speechRecognizeLunettes(this.token, audio)
      .then((result) => {
        this.lunettesSpeechRecognize = result.data.speechRecognize
        this.lunettes = result.data.references
        if (this.lunettes.length > 0) {
          this.chosenLunettes = this.lunettes[0]
        }
        this.hasSearchedLunettes = true
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error(String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },
    speechRecognizeMarques (audio) {
      this.$store.commit('loadFromAPI')
      api.speechRecognizeMarques(this.token, audio)
      .then((result) => {
        this.marqueSpeechRecognize = result.data.speechRecognize
        this.marquesProposed = result.data.marques
        if (this.marquesProposed.length > 0) {
          this.chosenMarque = this.marquesProposed[0]
        }
        this.hasSearchedMarques = true
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error(String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },
    speechRecognizeReferences (audio) {
      this.$store.commit('loadFromAPI')
      api.speechRecognizeReferences(this.token, this.chosenMarque.acronyme, audio)
      .then((result) => {
        this.referencesSpeechRecognize = result.data.speechRecognize
        this.references = result.data.references
        if (this.references.length > 0) {
          this.chosenReference = this.references[0]
        }
        this.hasSearchedReferences = true
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error(String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },
    validateReference (onglet) {
      this.$store.commit('loadFromAPI')
      const data = {}
      if (onglet == 'lunettes') {
        data['reference'] = this.chosenLunettes.reference
      } else {
        data['reference'] = this.chosenReference.reference
      }
      api.validateReference(this.token, data)
      .then((result) => {
        if (String(result.data.status) == '200') {
          alert(`Ok validé - Status code : ${result.data.status}`)
        }
        else {
          alert(`Erreur - Status code : ${result.data.status}`)
        }
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error(String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    }
  }
}
</script>
