<template>
  <div>
    <b-button 
        :disabled="isRecording"
        class="mr-2" 
        @click="record" 
        variant="outline-primary"
    > 
        Enregistrer 
    </b-button>
    <b-button
        :disabled="!isRecording"
        class="mr-2" 
        @click="stop" 
        variant="outline-danger"
    > 
        Stop 
    </b-button>
    <b-button
        :disabled="!audio"
        class="mr-2" 
        @click="play" 
        variant="outline-info"
    > 
        Play 
    </b-button>
  </div>
</template>

<script>
import recordAudio from "@/utils/recorder"

export default {
  name: "RecorderComponent",
  data() {
    return {
      isRecording: false,
      recorder: null,
      audio: null
    }
  },
  methods: {
    async record () {
      this.isRecording = true
      if (!this.recorder) {
        this.recorder = await recordAudio()
      }
      this.recorder.start()
    },
    async stop () {
      this.audio = await this.recorder.stop()
      this.$emit('stop', this.audio.audioChunks[0])
      this.isRecording = false
    },
    play () {
      this.audio.play()
    }
  }
}
</script>