<template>
  <div class="globalErrorModalComponent">
    <!-- ERROR MODAL -->
    <b-modal v-model="isGlobalErrorModal" size="lg" id="globalAPILoadingErrorModal" hide-footer title="Erreur de communication avec le serveur">
      <div class="text-center">
        <p>
          Une erreur s'est produite veuillez réessayer </p>
        <b-button class="mt-3" variant="outline-dark" @click="$store.commit('closeGlobalErrorModal')">
          Fermer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'globalErrorModalComponent',
  computed : {
    isGlobalErrorModal: {
      get () {
        return this.$store.state.isGlobalErrorModal
      },
      set () {
        this.$store.commit('closeGlobalErrorModal')
      }
    },
  }
}
</script>
