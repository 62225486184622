import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/login')
     return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
             return Promise.reject(error)
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
           return Promise.reject(error)
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   store.commit('openGlobalErrorModal')
   return Promise.reject(error)
});


export class APIService {
  getMarques (token) {
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    const url = apiBaseUrl + `/marques/`

    return axios_instance.get(url,
      {
        'headers': headers,
      })
  }

  speechRecognizeLunettes (token, audioBlob) {
    const formData = new FormData()
    formData.append('audio', audioBlob)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    const url = apiBaseUrl + `/speech-recognize/lunettes/`

    return axios_instance.post(url, formData,
      {
        'headers': headers,
      })
  }

  speechRecognizeMarques (token, audioBlob) {
    const formData = new FormData()
    formData.append('audio', audioBlob)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    const url = apiBaseUrl + `/speech-recognize/marques/`

    return axios_instance.post(url, formData,
      {
        'headers': headers,
      })
  }
  speechRecognizeReferences (token, marque, audioBlob) {
    const formData = new FormData()
    formData.append('marque', marque)
    formData.append('audio', audioBlob)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    const url = apiBaseUrl + `/speech-recognize/references/`

    return axios_instance.post(url, formData,
      {
        'headers': headers,
      })
  }

  validateReference(token, data) {
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    const url = apiBaseUrl + `/validate-reference/`

    return axios_instance.post(url, data,
      {
        'headers': headers,
      })
  }
}
